import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import SignUpBonus from '@/mixins/SignUpBonus';
export default {
  name: 'SignUpBonusProgress',
  mixins: [SignUpBonus],
  computed: {
    appMain: function appMain() {
      return this.$store.getters['player/profile'];
    },
    coins: function coins() {
      var _this$appMain, _this$appMain$signUpB;

      return (_this$appMain = this.appMain) === null || _this$appMain === void 0 ? void 0 : (_this$appMain$signUpB = _this$appMain.signUpBonus) === null || _this$appMain$signUpB === void 0 ? void 0 : _this$appMain$signUpB.coins;
    },
    entries: function entries() {
      var _this$appMain2, _this$appMain2$signUp;

      return (_this$appMain2 = this.appMain) === null || _this$appMain2 === void 0 ? void 0 : (_this$appMain2$signUp = _this$appMain2.signUpBonus) === null || _this$appMain2$signUp === void 0 ? void 0 : _this$appMain2$signUp.entries;
    },
    list: function list() {
      return [{
        title: 'Complete Profile',
        isCompleted: this.profileCompleted
      }, {
        title: 'Confirm Phone',
        isCompleted: this.appMain.confirmed
      }, {
        title: 'Confirm Email',
        isCompleted: this.appMain.emailConfirmed
      }, {
        title: 'Connect FB',
        isCompleted: this.fbCompleted
      }, {
        title: 'Get Sign Up Bonus',
        isCompleted: false
      }];
    }
  },
  methods: {
    close: function close() {
      this.$store.dispatch('modals/setSignUpBonusProgress', {
        isOpen: false
      });
    },
    handleClickBtn: function handleClickBtn() {
      this.goToNextStep();
      this.close();
    }
  }
};